import gsap from "gsap"

export const learnMoreLink = () => {
  const learnMoreLinks = document.querySelectorAll(".learn-more-link")

  if (!learnMoreLinks.length) return

  learnMoreLinks.forEach((block) => {
    gsap.fromTo(
      block,
      { opacity: 0, translateX: -30 },
      {
        opacity: 1,
        duration: 1.5,
        translateX: 0,
        scrollTrigger: {
          trigger: block
        }
      }
    )
  })
}
