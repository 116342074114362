import gsap from "gsap"
import { slitText } from "../utils/slit-text"

export const blog = () => {
  const blogBlock = document.querySelectorAll(".blog-fade-in-block")

  if (!blogBlock.length) return

  // animate block item
  blogBlock.forEach((block) => {
    const items = block.querySelectorAll(".blog-fade-in-item")

    gsap.fromTo(
      items,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
        stagger: 0.3,
        delay: 0.3,
        scrollTrigger: {
          trigger: block
        }
      }
    )
  })

  // animate text
  const textItems = document.querySelectorAll(".blog-text-js")
  if (!textItems.length) return

  textItems.forEach((text) => {
    slitText(text)

    const words = text.querySelectorAll(".word")

    gsap.fromTo(
      words,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
        stagger: 0.04,
        delay: 0.3,
        scrollTrigger: {
          trigger: text
        }
      }
    )
  })

  // animate blog link
  const blogLinks = document.querySelectorAll(".blog-link")
  if (!blogLinks.length) return

  blogLinks.forEach((link) => {
    const linkText = link.querySelector(".blog-link__text")
    const linkIcon = link.querySelector(".blog-link__icon")

    gsap.fromTo(
      link,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        stagger: 0.2,
        delay: 0.3,
        scrollTrigger: {
          trigger: link
        }
      }
    )

    gsap.fromTo(
      linkText,
      { translateX: -50 },
      {
        translateX: 0,
        duration: 1,
        stagger: 0.2,
        delay: 0.35,
        scrollTrigger: {
          trigger: link
        }
      }
    )

    gsap.fromTo(
      linkIcon,
      { translateX: 50 },
      {
        translateX: 0,
        duration: 1,
        stagger: 0.2,
        delay: 0.35,
        scrollTrigger: {
          trigger: link
        }
      }
    )
  })
}
