import gsap from "gsap"

export const sectionParallax = () => {
  gsap.utils.toArray(".section-parallax").forEach(function (container) {
    let image = container.querySelector("img")

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        scrub: true,
        pin: false
      }
    })
    tl.from(image, {
      yPercent: -30,
      ease: "none"
    }).to(image, {
      yPercent: 30,
      ease: "none"
    })
  })
}
