import gsap from "gsap"

export const singleLocation = () => {
  const images = document.querySelectorAll(".vacation-list-item")
  if (!images.length) return

  images.forEach(($block, index) => {
    const imgBefore = $block.querySelectorAll(".vacation-list-item__img-pseudo--before")
    const imgAfter = $block.querySelectorAll(".vacation-list-item__img-pseudo--after")

    const isDesktop = window.matchMedia("(min-width: 1025px)").matches

    gsap.fromTo(
      imgBefore,
      { opacity: 0, translateX: -50, translateY: 50 },
      {
        opacity: 1,
        duration: 1.25,
        translateX: 0,
        translateY: 0,
        delay: 0.3,
        scrollTrigger: {
          trigger: $block
        }
      }
    )

    gsap.fromTo(
      imgAfter,
      { opacity: 0, translateX: 50, translateY: -50 },
      {
        opacity: 1,
        duration: 1.25,
        translateX: 0,
        translateY: 0,
        delay: 0.3,
        scrollTrigger: {
          trigger: $block
        }
      }
    )

    // second element
    if (index === 1 && isDesktop) {
      gsap.fromTo(
        imgBefore,
        { opacity: 0, translateX: 50, translateY: 50 },
        {
          opacity: 1,
          duration: 1.25,
          translateX: 0,
          translateY: 0,
          delay: 0.3,
          scrollTrigger: {
            trigger: $block
          }
        }
      )

      gsap.fromTo(
        imgAfter,
        { opacity: 0, translateX: -50, translateY: -50 },
        {
          opacity: 1,
          duration: 1.25,
          translateX: 0,
          translateY: 0,
          delay: 0.3,
          scrollTrigger: {
            trigger: $block
          }
        }
      )
    }
  })
}
