import gsap from "gsap"

export const individualListingHero = () => {
  const section = document.querySelector(".individual-listing-hero")
  if (!section) return

  const heroSlider = section.querySelectorAll(".individual-listing-slider")
  const heroSliderButtons = section.querySelectorAll(".individual-listing-slider-btns")

  gsap.to(heroSlider, { opacity: 1, duration: 1.25 })

  gsap.from(heroSliderButtons, { top: "68%" })
  gsap.to(heroSliderButtons, { duration: 0.8, top: "50%" })
}
