import gsap from "gsap"

export const conciergeSection = () => {
  const conciergePage = document.querySelector(".concierge-page")
  if (!conciergePage) return
  const list = document.querySelector(".concierge-hero-list")
  const listItems = list.querySelectorAll(".concierge-hero-list__item")

  const animate = () => {
    // animate list items
    gsap.fromTo(
      listItems,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        stagger: 0.2,
        delay: 0.3
      }
    )

    // [Rejuvenate section]
    gsap.fromTo(
      ".rejuvenate__image-wrapper",
      { opacity: 0, translateX: 100 },
      {
        opacity: 1,
        duration: 1.5,
        translateX: 0,
        scrollTrigger: {
          trigger: ".rejuvenate__image-wrapper"
        }
      }
    )

    gsap.fromTo(
      ".rejuvenate-beauty-services",
      { opacity: 0, translateY: 100 },
      {
        opacity: 1,
        duration: 1.5,
        translateY: 0,
        scrollTrigger: {
          trigger: ".rejuvenate-beauty-services"
        }
      }
    )
  }

  animate()
}
