import { select } from "./components/select"
import { button } from "./ui/button"
// import { accordion } from "./components/accordion"
// import { formValidation } from "./components/form-validation"
// import { modal } from "./components/modal"
// import { tabs } from "./components/tabs"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Swiper from "swiper"
import { EffectFade, Navigation, Pagination, Thumbs } from "swiper/modules"
import { fadeInBlocks } from "./animation/fade-in-blocks"
import { fadeUpAnimation } from "./animation/fade-up"
import { slideFromLeft } from "./animation/slide-from-left"
import { slideFromRight } from "./animation/slide-from-right"
import { textAnimation } from "./animation/text-animation"
import { titles } from "./animation/titles"
import { ellipseBg } from "./components/ellipse-bg"
import { heroSearchFormField } from "./components/hero-search-form-field"
import { learnMoreLink } from "./components/learn-more-link"
import { maps } from "./components/maps"
import { scrollToTopBtn } from "./components/scroll-to-top-btn"
import { swiper } from "./components/swiper"
import { blog } from "./modules/blog"
import { conciergeSection } from "./modules/concierge"
import { datepicker } from "./modules/datepicker"
import { header } from "./modules/header"
import { heroAnimation } from "./modules/hero-section"
import { individualListingHero } from "./modules/individual-listing-hero"
import { inspiredCollection } from "./modules/inspired-collection"
import { navigation } from "./modules/navigaton"
import { ownersHero } from "./modules/owners-hero"
import { sectionParallax } from "./modules/section-parallax"
import { singleLocation } from "./modules/single-location"
import { sustainability } from "./modules/sustainability"
import { buttonSlider } from "./ui/button-slider"
import { browserDetect } from "./utils/browser-detect"
import { useDynamicAdapt } from "./utils/dynamic-adapt"

gsap.registerPlugin(ScrollTrigger)
Swiper.use([Pagination, Navigation, Thumbs, EffectFade])

window.addEventListener("load", () => {
  window.browserClient = browserDetect()
  const $loader = document.querySelector(".loader")

  // eslint-disable-next-line no-undef
  SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 1300,
    // Размер шага в пикселях
    stepSize: 50,
    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 2,
    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 90,
    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,
    // Поддержка тачпада
    touchpadSupport: false
  })

  // Show loader animation
  $loader && $loader.classList.add("active")

  // Show hidden elements
  document.querySelectorAll(".important_none")?.forEach(($el) => $el.classList.remove("important_none"))

  // ===== UI
  button()

  buttonSlider()

  scrollToTopBtn()

  // ===== Components
  // All selects
  select()

  // Hero Search Form Fields
  heroSearchFormField()

  // Swiper init
  swiper()

  // All accordions
  // const accordionsInstances = accordion()

  // Tabs
  // const tabsInstance = tabs()

  // Forms
  // const formsInstances = formValidation()

  // Modals
  // const modalsInstance = modal()
  // ===== Components end

  // ===== Modules
  // Nav
  navigation()

  // Header
  header()

  // Inspired collection
  inspiredCollection()
  // ===== Modules end

  // ===== Dynamic Adapt
  useDynamicAdapt()

  // Hide loader
  if ($loader) {
    setTimeout(() => {
      $loader.classList.add("hide")

      setTimeout(() => {
        // All titles
        titles()
        // All text elements
        textAnimation()
        // All fade up elements
        fadeUpAnimation()
        // All fade in elements
        fadeInBlocks()
        // All slide from right elements
        slideFromRight()
        // All slide from left elements
        slideFromLeft()

        // Learn More Link component
        learnMoreLink()

        // Other pages animations
        heroAnimation()
        ownersHero()
        individualListingHero()
        conciergeSection()
        blog()
        ellipseBg()
        singleLocation()
        sustainability()
        document.body.style.overflow = "initial"
      }, 900)
    }, 2000)
  }
})

// datepickers
datepicker()

// maps
maps()

// parallax section
sectionParallax()
