import L from "leaflet"
import GestureHandling from "leaflet-gesture-handling"
import "leaflet.markercluster"

export const maps = () => {
  const API_KEY = "b05dca70-684a-42ad-be39-5c983ccdf3e2"

  const customIcon = L.icon({
    iconUrl: "./svg/marker.svg",
    iconAnchor: [30, 65]
  })

  let config = {
    minZoom: 7,
    maxZoom: 18,
    gestureHandling: true
  }
  const zoom = 10

  const lat = 34.07693107170499
  const lng = -118.39627252827916

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling)

  // main map
  const mainMapSelector = document.querySelector("#mainMap")

  if (mainMapSelector) {
    const map = L.map("mainMap", config).setView([lat, lng], zoom)

    L.tileLayer(`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}?api_key=${API_KEY}`, {
      attribution:
        "&copy; <a href='https://www.stadiamaps.com/' target='_blank'>Stadia Maps</a> &copy; <a href='https://openmaptiles.org/' target='_blank'>OpenMapTiles</a> &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      ext: "png"
    }).addTo(map)

    L.marker([lat, lng], { icon: customIcon }).addTo(map)
  }

  // villas map
  const villasMapSelector = document.querySelector("#villasMap")

  if (villasMapSelector) {
    let points = [
      [34.011866, -118.432137, "Santa Monica"],
      [33.98454, -118.343704, "Inglewood"],
      [34.050163, -118.337537, "Beverly Hills"],
      [34.061845, -118.420436, "Westwood"],
      [34.061113, -118.462842, "Brentwood"],
      [34.156205, -118.465493, "Sherman Oaks"]
    ]

    const villasMap = L.map("villasMap", config).setView([lat, lng], zoom)

    L.tileLayer(`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}?api_key=${API_KEY}`, {
      attribution:
        "&copy; <a href='https://www.stadiamaps.com/' target='_blank'>Stadia Maps</a> &copy; <a href='https://openmaptiles.org/' target='_blank'>OpenMapTiles</a> &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      ext: "png"
    }).addTo(villasMap)

    for (let i = 0; i < points.length; i++) {
      const [lat, lng, popupText] = points[i]

      new L.marker([lat, lng], { icon: customIcon }).bindPopup(popupText).addTo(villasMap)
    }
  }

  // contact map
  const contactMapSelector = document.querySelector("#contactMap")

  const contactMapIcon = L.icon({
    iconUrl: "./svg/marker.svg",
    iconSize: [52, 52],
    iconAnchor: [26, 57],
    popupAnchor: [0, -42]
  })

  if (contactMapSelector) {
    const map = L.map("contactMap", config).setView([34.04526920099423, -118.24231345414853], zoom)

    L.tileLayer(`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}?api_key=${API_KEY}`, {
      attribution:
        "&copy; <a href='https://www.stadiamaps.com/' target='_blank'>Stadia Maps</a> &copy; <a href='https://openmaptiles.org/' target='_blank'>OpenMapTiles</a> &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      ext: "png"
    }).addTo(map)

    L.marker([34.04526920099423, -118.24231345414853], { icon: contactMapIcon }).addTo(map)

    setTimeout(map.invalidateSize.bind(map), 200)
  }

  // villas for rent map
  const rentVillasMapSelector = document.querySelector("#rentVillasMap")

  if (rentVillasMapSelector) {
    const rentVillasMapIcon = L.icon({
      iconUrl: "./svg/marker.svg",
      iconSize: [32, 32],
      iconAnchor: [16, 37],
      popupAnchor: [0, -22]
    })

    let points = [
      [34.011866, -118.432137, "Santa Monica"],
      [33.98454, -118.343704, "Inglewood"],
      [34.050163, -118.337537, "Beverly Hills"],
      [34.061845, -118.420436, "Westwood"],
      [34.061113, -118.462842, "Brentwood"],
      [34.156205, -118.465493, "Sherman Oaks"]
    ]

    const rentVillasMap = L.map("rentVillasMap", config).setView([lat, lng], 11)

    L.tileLayer(`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}?api_key=${API_KEY}`, {
      attribution:
        "&copy; <a href='https://www.stadiamaps.com/' target='_blank'>Stadia Maps</a> &copy; <a href='https://openmaptiles.org/' target='_blank'>OpenMapTiles</a> &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      ext: "png"
    }).addTo(rentVillasMap)

    let markers = L.markerClusterGroup()

    for (let i = 0; i < points.length; i++) {
      const [lat, lng, title] = points[i]

      let marker = new L.marker([lat, lng], { icon: rentVillasMapIcon }).bindPopup(title)
      markers.addLayer(marker)
    }

    rentVillasMap.addLayer(markers)
  }
}
