import gsap from "gsap"

export const fadeInBlocks = () => {
  const blocks = document.querySelectorAll(".js-fade-in-block")
  if (!blocks.length) return

  // === Init
  blocks.forEach(($block) => {
    const items = $block.querySelectorAll(".js-fade-in-item")

    gsap.fromTo(
      items,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 2,
        stagger: 0.2,
        delay: 0.2,
        scrollTrigger: {
          trigger: $block
        }
      }
    )
  })
}
