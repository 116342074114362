import gsap from "gsap"

export const sustainability = () => {
  const imgBlock = document.querySelectorAll(".js-sustainability-block")
  if (!imgBlock.length) return

  imgBlock.forEach(($block) => {
    const items = $block.querySelectorAll(".js-sustainability-item")

    const clearProperties = () => {
      gsap.set(items, { clearProps: "x,y" })
    }

    gsap.fromTo(
      $block,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 1.5,
        delay: 0.1,
        scrollTrigger: {
          trigger: $block,
          start: "top bottom-=50%"
        }
      }
    )

    gsap.from(items, {
      x: 0,
      y: 0,
      duration: 1.5,
      delay: 0.1,
      scrollTrigger: {
        trigger: $block,
        start: "top bottom-=50%"
      }
    })

    window.addEventListener("resize", clearProperties)
  })
}
