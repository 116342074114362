import flatpickr from "flatpickr"

export const datepicker = () => {
  const datepickerSelector = document.querySelector(".datepicker-js")
  if (!datepickerSelector) return

  flatpickr("#dateFrom", {
    dateFormat: "m/d/Y",
    defaultDate: "today",
    disableMobile: "true"
  })
  flatpickr("#dateTo", {
    dateFormat: "m/d/Y",
    defaultDate: new Date().fp_incr(7),
    disableMobile: "true"
  })

  // tailor-made holiday
  flatpickr("#check-in", {
    dateFormat: "m/d/Y",
    disableMobile: "true"
  })
  flatpickr("#check-out", {
    dateFormat: "m/d/Y",
    disableMobile: "true"
  })

  // concierge page
  const conciergeDatePickers = []

  const conciergeDatePickerConfig = {
    dateFormat: "m/d/Y",
    defaultDate: "today",
    disableMobile: "true"
  }
  const conciergeTimePickerConfig = {
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
    dateFormat: "H:i",
    defaultDate: "10:00",
    disableMobile: "true"
  }

  // date pickers
  conciergeDatePickers.push(flatpickr("#start-day", conciergeDatePickerConfig))
  conciergeDatePickers.push(flatpickr("#end-day", conciergeDatePickerConfig))
  // time pickers
  conciergeDatePickers.push(flatpickr("#start-time", conciergeTimePickerConfig))
  conciergeDatePickers.push(flatpickr("#end-time", conciergeTimePickerConfig))

  const filteredDatePickers = conciergeDatePickers.filter((picker) => !Array.isArray(picker))

  filteredDatePickers.forEach((datepicker) => {
    datepicker.config.onChange.push(function (_, dateStr) {
      if (dateStr) {
        datepicker.element.classList.add("has-value")
      } else {
        datepicker.element.classList.remove("has-value")
      }
    })
  })
}
