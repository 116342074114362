import gsap from "gsap"
import { slitText } from "../utils/slit-text"

export const titles = () => {
  const sectionTitles = document.querySelectorAll(".section-title")
  const upperTitles = document.querySelectorAll(".upper-title")
  const titleLines = document.querySelectorAll(".title-line")
  const titleIcons = document.querySelectorAll(".upper-title-icon")

  // === Methods
  const animateTitle = ($title) => {
    slitText($title)

    const letters = $title.querySelectorAll(".word")

    gsap.fromTo(
      letters,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        stagger: 0.2,
        delay: 0.2,
        scrollTrigger: {
          trigger: $title,
          start: "bottom bottom"
        }
      }
    )
  }

  // === Init
  // Upper titles
  upperTitles.length &&
    upperTitles.forEach(($title) => {
      animateTitle($title)
    })

  // Section titles
  sectionTitles.length &&
    sectionTitles.forEach(($title) => {
      animateTitle($title)
    })

  // Section titles
  titleIcons.length &&
    titleIcons.forEach(($icon) => {
      gsap.fromTo(
        $icon,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.5,
          scrollTrigger: {
            trigger: $icon,
            start: "bottom bottom"
          }
        }
      )
    })

  // Title lines
  titleLines.length &&
    titleLines.forEach(($line) => {
      const currentWidth = $line.offsetWidth

      gsap.fromTo(
        $line,
        { width: 0 },
        {
          width: currentWidth,
          scrollTrigger: {
            trigger: $line,
            start: "bottom bottom"
          }
        }
      )
    })
}
