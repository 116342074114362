import gsap from "gsap"

export const ellipseBg = () => {
  const ellipseBg = document.querySelector(".ellipse-bg")
  if (!ellipseBg) return

  // circles
  const circles = ellipseBg.querySelectorAll(".ellipse-bg-circle")

  gsap.fromTo(
    circles,
    { opacity: 0, scale: 0 },
    {
      opacity: 1,
      scale: 1,
      duration: 1.25,
      stagger: 0.1,
      delay: 0.2,
      scrollTrigger: {
        trigger: ellipseBg
      }
    }
  )

  // lines
  const lines = ellipseBg.querySelectorAll(".ellipse-bg-line")

  gsap.fromTo(
    lines,
    { opacity: 0, strokeDasharray: "0 300" },
    {
      opacity: 1,
      strokeDasharray: "200 100",
      duration: 1.25,
      stagger: 0.3,
      delay: 1,
      scrollTrigger: {
        trigger: ellipseBg
      }
    }
  )
}
