import gsap from "gsap"

export const slideFromLeft = () => {
  const slideInBlock = document.querySelectorAll(".js-slide-from-left-block")
  if (!slideInBlock.length) return

  slideInBlock.forEach(($block) => {
    const items = $block.querySelectorAll(".js-slide-from-left-item")

    gsap.fromTo(
      items,
      { x: -100, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 1.5,
        delay: 0.2,
        stagger: 0.2,
        scrollTrigger: {
          trigger: $block,
          start: "top bottom-=40%"
        }
      }
    )
  })
}
