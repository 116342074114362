import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollToPlugin)

const TO_TOP_VISIBLE_CLASS = "to-top-btn--visible"
const SCROLL_OFFSET = 320
const SHOW_DELAY = 2000

export const scrollToTopBtn = () => {
  const scrollToTopBtn = document.querySelector(".to-top-btn")

  let isScrolling
  let prevScrollPos = window.scrollY

  const showToTopButton = () => {
    scrollToTopBtn.classList.add(TO_TOP_VISIBLE_CLASS)

    isScrolling = setTimeout(() => {
      hideToTopButton()
    }, SHOW_DELAY)
  }

  const hideToTopButton = () => {
    clearTimeout(isScrolling)
    scrollToTopBtn.classList.remove(TO_TOP_VISIBLE_CLASS)
  }

  const onScroll = () => {
    const currentScrollPos = window.scrollY

    if (isScrolling) {
      clearTimeout(isScrolling)
    }

    if (currentScrollPos > SCROLL_OFFSET && currentScrollPos < prevScrollPos) {
      showToTopButton()
    } else {
      hideToTopButton()
    }

    prevScrollPos = currentScrollPos
  }

  const scrollToTop = () => {
    gsap.to(window, { duration: 1, scrollTo: { y: 0, ease: "power2.inOut" } })
  }

  // Event listeners
  window.addEventListener("scroll", onScroll)
  scrollToTopBtn.addEventListener("click", scrollToTop)
}
