export const slitText = ($el) => {
  const words = $el.innerHTML.trim().split(/\s+/)

  $el.innerHTML = words
    .map((word) => {
      if (word.includes("<") && word.includes(">")) {
        return word
      }

      let isAmpersand = false

      const letters = Array.from(word, (letter) => {
        if (letter === "&") {
          isAmpersand = true
        }

        return isAmpersand ? letter : `<span class="letter">${letter}</span>`
      }).join("")

      return `<span class="word">${letters}</span>`
    })
    .join(" ")
}
