import Swiper from "swiper"

export const swiper = () => {
  /* [MAIN PAGE] */

  const $eventsSliderContainer = document.querySelector(".events__slider .slider-container")

  new Swiper($eventsSliderContainer, {
    speed: 750,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
      769: {
        slidesPerView: 1.35
      },
      1025: {
        slidesPerView: 1.55
      }
    },
    navigation: {
      prevEl: ".events__slider-btn.prev",
      nextEl: ".events__slider-btn.next"
    },
    pagination: {
      el: ".events__slider",
      renderBullet: (index, className) => {
        const paginationData = $eventsSliderContainer.dataset?.boolets
        if (!paginationData) return

        const bulletItem = JSON.parse($eventsSliderContainer.dataset?.boolets)[index]

        return `<span class="${className} ${index === 0 ? "swiper-pagination-bullet-active" : ""}">
          <span class="${className}-circle"></span>
          <span class="${className}-value">${bulletItem}</span>
        </span>`
      }
    }
  })

  new Swiper(".testimonials__slider .slider-container", {
    speed: 500,
    slidesPerView: 1,
    spaceBetween: 40,
    navigation: {
      prevEl: ".testimonials__slider-btn._prev",
      nextEl: ".testimonials__slider-btn._next"
    }
  })

  new Swiper(".partners__slider .slider-container", {
    loop: true,
    slidesPerView: 1.75,
    spaceBetween: 20,
    breakpoints: {
      376: {
        slidesPerView: 2.5
      },
      577: {
        slidesPerView: 3.5
      },
      769: {
        slidesPerView: 4.5
      },
      1025: {
        slidesPerView: 5
      }
    }
  })

  /* [SHORT TERM RENTALS PAGE] */

  // homes swiper
  new Swiper(".homes-slider .slider-container", {
    speed: 800,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    pagination: {
      el: ".homes-slider-pagination",
      bulletClass: "swiper-pagination-bullet homes-slider-pagination__bullet",
      bulletActiveClass: "homes-slider-pagination__bullet--active"
    },
    navigation: {
      prevEl: ".homes-slider-button._prev",
      nextEl: ".homes-slider-button._next"
    },
    breakpoints: {
      577: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      1025: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1621: {
        slidesPerView: 4,
        slidesPerGroup: 4
      }
    }
  })

  // travelers swiper
  new Swiper(".travelers-slider .slider-container", {
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 220,
    navigation: {
      prevEl: ".travelers-slider-button._prev",
      nextEl: ".travelers-slider-button._next"
    },
    grabCursor: true
  })

  /* [OWNER PAGE] */

  // testimonials swiper
  new Swiper(".owners-slider .slider-container", {
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 220,
    navigation: {
      prevEl: ".owners-slider-button._prev",
      nextEl: ".owners-slider-button._next"
    },
    grabCursor: true
  })

  // partners swiper
  new Swiper(".owner-partners-slider .slider-container", {
    slidesPerView: "auto",
    spaceBetween: 0,
    initialSlide: 3,
    centeredSlides: true,
    centeredSlidesBounds: true,
    grabCursor: true,
    breakpoints: {
      577: {
        spaceBetween: 20
      }
    }
  })

  /* [INDIVIDUAL LISTING PAGE] */

  // hero swiper
  new Swiper(".individual-listing-slider .slider-container", {
    speed: 750,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
      769: {
        slidesPerView: 1.35
      },
      1025: {
        slidesPerView: 1.55
      }
    },
    navigation: {
      prevEl: ".individual-listing-slider-btn.prev",
      nextEl: ".individual-listing-slider-btn.next"
    }
  })

  // testimonials swiper
  new Swiper(".testimonials-il-slider .slider-container", {
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 220,
    navigation: {
      prevEl: ".testimonials-il-slider-button._prev",
      nextEl: ".testimonials-il-slider-button._next"
    },
    grabCursor: true
  })

  /* [BLOG PAGE] */
  new Swiper(".blog-filter-slider", {
    speed: 300,
    slidesPerView: "auto",
    spaceBetween: 10,
    freeMode: true,
    breakpoints: {
      1441: {
        spaceBetween: 20
      }
    }
  })

  /* [EVENT PAGE] */
  new Swiper(".event-services-slider", {
    speed: 500,
    effect: "fade",
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      1025: {
        allowTouchMove: false
      }
    },
    navigation: {
      prevEl: ".event-services-slider-button._prev",
      nextEl: ".event-services-slider-button._next"
    },
    pagination: {
      el: ".event-services-pagination",
      type: "fraction",
      formatFractionCurrent: function (number) {
        return ("0" + number).slice(-2)
      },
      formatFractionTotal: function (number) {
        return ("0" + number).slice(-2)
      },
      renderFraction: function (currentClass, totalClass) {
        return (
          // eslint-disable-next-line quotes
          '<span class="event-services-pagination__active-num ' +
          currentClass +
          // eslint-disable-next-line quotes
          '"></span>' +
          // eslint-disable-next-line quotes
          '<span class="event-services-pagination__slash">/</span>' +
          // eslint-disable-next-line quotes
          '<span class="' +
          totalClass +
          // eslint-disable-next-line quotes
          '"></span>'
        )
      }
    }
  })

  /* [SINGLE LOCATION PAGE] */

  // villas swiper
  new Swiper(".sl-villas-rent-slider", {
    speed: 400,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    grabCursor: true,
    pagination: {
      el: ".homes-slider-pagination",
      bulletClass: "swiper-pagination-bullet homes-slider-pagination__bullet",
      bulletActiveClass: "homes-slider-pagination__bullet--active"
    },
    navigation: {
      prevEl: ".homes-slider-button._prev",
      nextEl: ".homes-slider-button._next"
    },
    breakpoints: {
      769: {
        speed: 600,
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      1025: {
        speed: 600,
        spaceBetween: 31,
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      1201: {
        speed: 800,
        spaceBetween: 20,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1441: {
        speed: 800,
        spaceBetween: 25,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1621: {
        speed: 800,
        spaceBetween: 31,
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    }
  })

  // advisors tips swiper
  new Swiper(".advisors-tips-slider .swiper-container", {
    speed: 400,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 32,
    grabCursor: true,
    pagination: {
      el: ".advisors-slider-control .homes-slider-pagination",
      bulletClass: "swiper-pagination-bullet homes-slider-pagination__bullet",
      bulletActiveClass: "homes-slider-pagination__bullet--active"
    },
    navigation: {
      prevEl: ".homes-slider-button._prev",
      nextEl: ".homes-slider-button._next"
    },
    breakpoints: {
      576: {
        speed: 600,
        spaceBetween: 32,
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      1025: {
        speed: 800,
        spaceBetween: 32,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1201: {
        speed: 800,
        spaceBetween: 50,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1441: {
        speed: 800,
        spaceBetween: 66,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1621: {
        speed: 800,
        spaceBetween: 76,
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    }
  })
}
