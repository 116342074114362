import gsap from "gsap"

export const ownersHero = () => {
  // hero selector
  const section = document.querySelector(".owner-hero")
  if (!section) return
  // columns selectors
  const textCol = section.querySelectorAll(".owner-hero__box")
  const formCol = section.querySelectorAll(".owner-hero-form__wrapper")

  const animateColumns = () => {
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (screenWidth <= 1024) {
      gsap.from(textCol, { translateY: -50 })
      gsap.to(textCol, { opacity: 1, duration: 1, stagger: 0.2, translateY: 0 })

      gsap.from(formCol, { translateY: 50 })
      gsap.to(formCol, { opacity: 1, duration: 1, stagger: 0.2, translateY: 0 })
    } else {
      gsap.from(textCol, { translateX: -50 })
      gsap.to(textCol, { opacity: 1, duration: 1, stagger: 0.2, translateX: 0 })

      gsap.from(formCol, { translateX: 50 })
      gsap.to(formCol, { opacity: 1, duration: 1, stagger: 0.2, translateX: 0 })
    }
  }

  // init
  animateColumns()
}
